import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import { navigate } from 'gatsby'
import { useCart } from '@chordcommerce/gatsby-theme-autonomy'
import { Page } from '~/components/Layout'
import PageSpinner from '~/components/Generic/PageSpinner'

const DiscountPage = ({ params: { name } }) => {
  const location = useLocation()
  const { addPromoCode } = useCart()

  useEffect(() => {
    const add = async (name, redirect) => {
      try {
        await addPromoCode({ promoCode: name })
        navigate(redirect)
      } catch (error) {
        navigate(redirect)
      }
    }
    const params = new URLSearchParams(location.search)
    const redirect = params.get('redirect') || '/'
    params.delete('redirect')
    const redirectParams = params.toString() && `?${params.toString()}`
    add(name, redirect + redirectParams)
  }, [addPromoCode, location.search, name])

  return (
    <Page>
      <PageSpinner />
    </Page>
  )
}

DiscountPage.propTypes = {
  params: PropTypes.shape({
    name: PropTypes.string
  })
}

export default DiscountPage
